
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';

import Navigator from '@/views/new-design/layout/Navigator.vue';
import UserSelect from '@/components/forms/dropdown/UserSelect.vue';

import { Getters, Actions } from '@/store/enums/UserEnums';
import {
  Getters as NotificationGetters,
  Actions as NotificationActions,
} from '@/store/enums/NotificationEnums';
import { capitalize } from '@/utils/text';

export default defineComponent({
  components: {
    UserSelect,
    Navigator,
  },
  data: () => ({
    loadingSettings: true,
    dueAuthorization: '',
    monthlyReport: '',
  }),
  computed: {
    ...mapGetters({
      users: Getters.GET_USERS,
      settings: NotificationGetters.GET_CURRENT_SETTINGS,
    }),
    userOptions() {
      let userOptions = [] as any;

      if (typeof this.users !== 'undefined') {
        this.users.map((user) => {
          userOptions.push({
            value: user.id,
            label:
              capitalize(user.first_name) +
              ' ' +
              capitalize(user.last_name) +
              ' (' +
              capitalize(user.roles[0]) +
              ')',
          });
        });
      }
      return userOptions;
    },
  },
  async mounted() {
    await this.handleFetchCurrentSettings();

    this.handleFetchUsers();
  },
  methods: {
    ...mapActions({
      fetchUsers: Actions.FETCH_USERS,
      fetchCurrentSettings: NotificationActions.FETCH_CURRENT_SETTINGS,
      saveSettings: NotificationActions.SAVE_SETTINGS,
    }),
    async handleFetchUsers() {
      if ((await this.users.length) > 0) return;

      await this.fetchUsers();
    },
    async handleFetchCurrentSettings() {
      await this.fetchCurrentSettings()
        .then(() => {
          this.loadingSettings = false;
        })
        .catch(() => {
          this.loadingSettings = false;
        });
    },
    handleDueAuthorizationChange(value) {
      this.dueAuthorization = value;
    },
    handleDueAuthorizationClose() {
      const payload = {
        systemName: 'due_authorization',
        user_id: this.dueAuthorization ? this.dueAuthorization : null,
      };
      this.saveSettings(payload).then((data) => {
        this.dueAuthorization = data['due_authorization'];
      });
    },
    handleMonthlyReportChange(value) {
      this.monthlyReport = value;
    },
    handleMonthlyReportClose() {
      const payload = {
        systemName: 'monthly_report',
        user_id: this.monthlyReport ? this.monthlyReport : null,
      };
      this.saveSettings(payload).then((data) => {
        this.monthlyReport = data['monthly_report'];
      });
    },
  },
  watch: {
    settings(values) {
      if (!values) return;

      this.dueAuthorization = values.due_authorization;
      this.monthlyReport = values.monthly_report;
    },
  },
});
